<template>
  <div
      class="loginView rootView"
      v-loading="storeLoading"
      element-loading-text="登录中..."
  >
    <div class="logo-box">
      <img class="img-logo" :src="logo"/>
    </div>
    <div class="login-box">
      <div class="logoView">
        洗衣通智慧收银台
      </div>
      <div class="titleView">
        <label class="label-title">登录</label>
        <div class="typeView">
          <label></label>
          <span></span>
          <label class="select">手机号登录</label>
        </div>
      </div>
      <div class="loginView">
        <div style="margin-bottom:15px">
          <el-input
              placeholder="请输入手机号"
              type="text"
              v-model.trim="loginForm.username"
              clearable
          >
          </el-input>
        </div>
        <div>
          <el-input
              placeholder="请输入密码"
              type="password"
              v-model.trim="loginForm.password"
              show-password
              clearable
              @keyup.enter.native="getStoreList"
          >
          </el-input>
        </div>
        <el-button class="er_password" type="text" @click="btnPaw"
        >忘记密码
        </el-button
        >
        <el-button class="btn-login" type="primary" @click="getStoreList"
        >登录
        </el-button
        >
        <!--      选择门店-->
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        width="32%"
        @close="handleCloseStore"
        v-loading="loginLoading"
        element-loading-text="登录中..."
    >
      <template slot="title">
        <div style="font-size: 17px;font-family: PingFangSC-Medium">
          选择登录门店
        </div>
      </template>
      <div class="father">
        <div class="el-top">
          <el-input
              autocomplete="off"
              v-model="input"
              placeholder="输入关键字检索"
          ></el-input>
        </div>
        <div class="el_content" v-if="dataList.length !== 0">
          <div
              @click="handleStore(item)"
              class="listItem"
              v-for="item in dataList"
              :key="item.id"
              :class="{ select: loginForm.storeId === item.id }"
          >
            <div :style="loginForm.storeId === item.id?'color: rgba(51, 112, 255, 1)':''">店号{{ item.id }} -
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="el_content" v-else>暂无门店</div>
      </div>
      <div class="el_bottom">
        <el-button @click="handleLogin" style="width:80%;" type="primary">
          确认
        </el-button>
      </div>
    </el-dialog>

    <!--    忘记密码弹框-->
    <transition name="forgetFullscreen">
      <forget v-if="activeView" @closeForget="closeForget"></forget>
    </transition>
  </div>
</template>

<script>
import {getStoreList} from "@/api/store/user";
import {setStore} from "@/utils/store";
import logo from "_img/login-logo.png";
import {isMobile, validatenull} from "@/utils/validate";
import forget from "@/view/login/components/forget";

export default {
  name: "index",
  components: {forget},
  data() {
    return {
      logo,
      activeView: false,
      loginLoading: false,
      storeLoading: false,
      storeList: [],
      storePhone: "",
      storeName: "",
      loginForm: {
        username: "",
        password: "",
        storeId: "",
      },
      dialogVisible: false,
      loading: true,
      input: "",
      radio: "",
      list: [],
    };
  },
  methods: {
    // 点击外面的
    showInfo() {
      let _this = this;
      document.addEventListener("click", function (e) {
        let flag = e.target.contains(
            document.getElementsByClassName("chainView")[0]
        );
        if (!flag) return;
        _this.activeView = false;
      });
    },
    // 选择门店
    changeStore() {
      this.activeView = true;
      this.$nextTick(() => {
        this.$refs.query.focus();
      });
    },
    // 获取门店列表
    getStoreList() {
      // 校验手机号
      if (validatenull(this.loginForm.username)) {
        this.$message.error("请输入手机号");
        return false;
      }
      if (!isMobile(this.loginForm.username)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      if (validatenull(this.loginForm.password)) {
        this.$message.error("请输入密码");
        return false;
      }
      this.storeLoading = true;
      const params = new URLSearchParams();
      params.append("phone", this.loginForm.username);
      getStoreList(params).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
          this.list = res.data.data;
          this.dialogVisible = true;
          this.storeLoading = false;
        } else {
          this.storeLoading = false;
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    handleStore(item) {
      this.loginForm.storeId = item.id;
      setStore({name: "tenantId", content: item.id});
    },
    handleLogin() {
      // 判断是否选择了门店
      if (validatenull(this.loginForm.storeId)) {
        this.$message.error("请选择门店");
        return false;
      }
      this.loginLoading = true;
      // 用户选中门店点击确定  进行登录
      this.$store
          .dispatch("user/login", this.loginForm)
          .then(() => {
            this.$router.push({name: "get", params: {visible: "true"}});
            this.dialogVisible = false;
            this.Loading.service(options);

            // 跳转
          })
          .catch(() => {
            this.loginLoading = false;
          });
    },
    // 关闭选择门店框事件
    handleCloseStore() {
      this.loginForm.storeId = "";
    },
    btnPaw() {
      this.activeView = true;
    },
    closeForget() {
      this.activeView = false;
    },
  },
  computed: {
    dataList() {
      return this.list.filter((item) => {
        const searchStr = "店号" + item.id + "" + item.name;
        return searchStr.indexOf(this.input) !== -1;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.rootView {
  width: 100%;
  height: 100%;
}

.loginView {
  position: relative;
  text-align: center;

  .logo-box {
    position: absolute;
    top: 20px;
    left: 20px;

    .img-logo {
      width: 130px;
    }
  }

  .login-box {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .logoView {
    color: rgba(16, 16, 16, 1);
    font-size: 23px;
    font-weight: bold;
    padding: 0 0 30px 0;
  }

  .titleView {
    position: relative;
    left: 0;
    right: 0;
    margin: 15px auto 0 auto;
    display: flex;
    width: 300px;
    line-height: 32px;
    transition: transform 0.9s, opacity 0.9s;

    > .label-title {
      text-align: left;
      font-size: 18px;
      font-family: PingFangSC-Medium;
      width: 80px;
      color: rgba(16, 16, 16, 1);
    }

    > .typeView {
      flex: 1;
      text-align: right;

      > label {
        display: inline-block;
        color: #666;
      }

      > label.select {
        color: rgba(51, 112, 255, 1);
        font-family: PingFangSC-Medium;
      }
    }
  }

  .loginView {
    margin-top: 15px;
    display: inline-block;
    width: 300px;

    > .inputView {
      position: relative;
      width: 100%;
      height: 50px;
      background: #f8f8f8;
      border-radius: 5px;
      margin-bottom: 15px;
      padding: 13px;
      transition: transform 0.5s, opacity 0.5s;

      > .icon {
        position: absolute;
        right: 12px;
        top: 17px;
      }

      > input {
        display: block;
        line-height: 24px;
        width: 100%;
        height: 24px;
        font-size: 14px;
        color: #333;
        text-align: center;
        border: none;
        outline: none;
        padding: 0;
        background: transparent;
        tap-highlight-color: transparent;
      }

      > input:disabled {
        color: #333;
        text-fill-color: #333;
        opacity: 1;
      }
    }

    > .inputView:nth-child(2) {
      transition-delay: 0.2s;
    }

    > .inputView:nth-child(3) {
      transition-delay: 0.3s;
    }

    > .btn-login {
      transition: transform 0.5s, opacity 0.5s;
      transition-delay: 0.4s;
      width: 100%;
      height: 45px;
      font-weight: 500;
      font-size: 16px;
      margin: 0;
    }
  }

  .popView {
    > .chainView {
      position: absolute;
      top: 0;
      right: 0;
      width: 352px;
      height: 100%;
      background: #fff;
      box-shadow: 0 0 12px rgba(220, 220, 220, 0.4);
      padding: 0 15px;

      > .searchView {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px 95px 15px 15px;
        overflow: hidden;
        background: #fff;
        z-index: 10;

        > .btn-search {
          position: absolute;
          top: 15px;
          right: 15px;
          width: 70px;
          height: 40px;
        }
      }

      > .listView {
        text-align: left;
        padding-left: 0;

        > .listItem {
          // height: 400px;
          float: left;
          padding: 15px 0;
          line-height: 20px;
          font-size: 14px;
          color: #333;
          border-bottom: 1px dashed rgba(220, 220, 220, 0.7);

          > label {
            position: absolute;
            float: left;
          }
        }
      }

      .tipView {
        color: #999;
        text-align: center;
        font-size: 13px;
        line-height: 200px;
      }
    }
  }

  > .popView .listView {
    display: block;
    height: 100%;
    overflow-y: auto;
    padding-top: 70px;
    padding-bottom: 15px;
    padding-left: 0;
    margin: 0;
  }
}

div {
  box-sizing: border-box;
  tap-highlight-color: rgba(0, 0, 0, 0);
}

input[type="text" i] {
  padding: 1px 2px;
}

.popView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

//动画过程
.paidFullscreen-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.paidFullscreen-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.paidFullscreen-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.er_password {
  font-size: 14px;
  color: #333;
  float: right;
}

.father {
  position: relative;
  height: 100%;

  .el_content {
    height: 400px;
    overflow-y: auto;
    bottom: 12px;

    .listItem {
      padding: 15px 0;
      line-height: 20px;
      font-size: 14px;
      color: #333;
      border-bottom: 1px solid rgba(220, 220, 220, 0.7);
      display: flex;
    }

    > div:last-child {
      border-bottom: none;
    }

    > div.select {
      background: #fff url("../../assets/img/icon_Select.png") right center /
        24px no-repeat;
    }
  }

  .el_bottom {
    margin-top: 12px;
    padding: 30px 0 0 0;
  }

  /deep/ .el-input__inner {
    background: #f8f8f8;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 13px;
    transition: transform 0.5s, opacity 0.5s;
    border: 0px;
  }
}

//动画过程
.forgetFullscreen-enter {
  animation: forgetFullscreen-dialog-fade-in 1s ease;
}

.forgetFullscreen-leave {
  animation: forgetFullscreen-dialog-fade-out 0.5s ease forwards;
}

.forgetFullscreen-enter-active {
  animation: forgetFullscreen-dialog-fade-in 0.5s ease;
}

.forgetFullscreen-leave-active {
  animation: forgetFullscreen-dialog-fade-out 0.5s ease forwards;
}

@keyframes forgetFullscreen-dialog-fade-in {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes forgetFullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 100%, 0);
    opacity: 1;
  }
}

/deep/ .el-input__inner {
  height: 45px;
}
</style>
