<template>
  <div class="forgotView">
    <div class="topView" @click="closeForget">
<!--      <button class="btn-back"></button>-->
      <span class="btn-back iconfont icon-icon_wrong"></span>
    </div>
    <div v-show="this.current == 0" class="contentView">
      <div class="header">
        <div class="active"></div>
        <div :class="current > 0 ? 'active' : ''"></div>
        <div :class="current > 1 ? 'active' : ''"></div>
      </div>
      <div class="titleView">
        检验手机号
      </div>
      <div class="imgdiv"></div>
      <div class="tipView">
        我们为了保护您的账户安全,将会通过手机验证码身份后，才会允许修改该手机账号的密码
      </div>
      <div class="el_input">
        <div class="myform">
          <el-form :model="form" :rules="rules" ref="mainform">
            <el-form-item prop="phone">
              <el-input v-model="form.phone" placeholder="请输入11位手机号"
                        onkeyup="this.value=this.value.replace(/\D/g,'')"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="code">
                <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
                <el-button class="btn-code" @click="getyzcode" type="text" size="mini" v-if="showbtn">发送验证码</el-button>
                <el-button class="btn-code" type="text" size="mini" :disabled="attcode" v-else>{{ code_ts }}</el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="margin-top: 12px;" @click="submitForm1('rules')">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div v-show="this.current == 1" class="contentView">
      <div class="header">
        <div class="active"></div>
        <div :class="current > 0 ? 'active' : ''"></div>
        <div :class="current > 1 ? 'active' : ''"></div>
      </div>
      <div class="titleView">
        输入新密码
      </div>
      <div class="imgdiv"></div>
      <div class="tipView">您已通过身份验证，重新设置新的密码，这次一定要妥善保管好哦~</div>
      <div class="el_input">
        <div class="myform">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="pass">
              <el-input v-model="ruleForm.pass" type="password" placeholder="请输入新的密码"></el-input>
            </el-form-item>
            <el-form-item prop="checkPass">
              <div class="code">
                <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" placeholder="请再次输入一次新密码">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="margin-top: 12px;" @click="submitForm('rules')">下一步</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div v-show="this.current == 2" class="contentView">
      <div class="header">
        <div class="active"></div>
        <div :class="current > 0 ? 'active' : ''"></div>
        <div :class="current > 1 ? 'active' : ''"></div>
      </div>
      <div class="titleView">
        重置密码成功
      </div>
      <div class="successImg"></div>
      <div class="tipView">您已通过身份验证，重新设置新的密码，这次一定要妥善保管好哦~</div>
      <div class="el_input">
        <el-button type="primary" style="margin-top: 12px;" @click="goLogin">去登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {forgetPass, sendCode} from '@/api/store/user'

export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      confirm: true,  //提交验证按钮判断
      attcode: true,  //点击获取验证码按钮判断
      showbtn: true, // 展示获取验证码或倒计时按钮判断
      yzcode: '',
      code_ts: '获取验证码', //倒计时提示文字
      sec: 60,// 倒计时秒数
      current: 0,
      cell: '',//获取手机验证后返回的状态
      isDisabled: false,
      form: {
        phone: '',
        code: '',
      },
      ruleForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        phone: [
          {
            pattern: /(^1\d{10}$)|(^[0-9]\d{7}$)/,
            trigger: 'blur',
            message: '请输入正确的手机号码'
          },
          {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            trigger: 'blur',
            message: '请输入数字'
          },
          {
            required: true,
            trigger: 'blur',
            message: '手机号不能为空'
          }
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            message: '验证码不能为空'
          }
        ],
        pass: [
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/,
            trigger: 'blur',
            message: '密码至少包含 数字和英文，长度8-20'
          },
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'}
        ],
      },
    };
  },
  methods: {
    //验证码
    getyzcode() {
      let cell = this.form.phone;
      let reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
      if (reg.test(cell)) {
        let params = {
          phone: this.form.phone,
          type: 1
        }
        forgetPass(params).then((res) => {
          if (res.data.code == 0) {
            let timer = setInterval(() => {
              this.sec = this.sec - 1
              this.code_ts = this.sec + 'S后重新获取'
              this.showbtn = false
              if (this.sec === 0) {
                clearInterval(timer)
                this.sec = 60
                this.code_ts = this.sec + 'S后重新获取'
                this.showbtn = true
              }
            }, 1000)
            sendCode(this.form.phone).then((res) => {
              if (res.data.code == 0) {
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.showbtn = false
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    next() {
      if (this.current++ > 2) this.current = 0;
      console.log(this.current, 'll');
    },
    submitForm1() {
      this.$refs.mainform.validate((valid) => {
        if (valid) {
          let params = {
            phone: this.form.phone,
            smsCode: this.form.code,
            type: 2
          }
          forgetPass(params).then((res) => {
            if (res.data.code == 0) {
              console.log('成功');
              this.next();
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log('aaa');
          let params = {
            phone: this.form.phone,
            smsCode: this.form.code,
            newPassWord: this.ruleForm.pass,
            type: 3
          }
          forgetPass(params).then((res) => {
            if (res.data.code == 0) {
              this.next();
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    goLogin() {
      this.$emit('closeForget');
      this.$router.push({path: '/login'})
    },
    closeForget() {
      this.form = {
        phone: '',
        code: '',
      }
      this.ruleForm = {
        pass: '',
        checkPass: ''
      }
      this.$emit('closeForget');
    }
  }
}
</script>

<style scoped lang="scss">
.forgotView {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;

  > .topView {
    overflow: hidden;
    padding: 15px 0 10px 0;

    > .btn-back {
      margin-left: 15px;
      float: left;
      width: 50px;
      height: 40px;
      font-size: 20px;
    }

    > button {
      background: transparent;
      border: none;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
    }
  }

  > .contentView {
    position: relative;
    width: 350px;
    left: 0;
    right: 0;
    margin: auto;
  }
}


.header {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.header div {
  width: 130px;
  height: 4px;
  margin-left: 5px;;
  background-color: #dddddd;
}

.active {
  background-color: #1E90FF !important;
}

.el_input {
  /* text-align: center; */
  width: 285px;
  margin: 0 auto;
}

/deep/ .el-input__inner {
  /* margin: 12px; */
  width: 300px;
}

.el-button--primary {
  width: 300px;
}

.code {
  position: relative;
  /* display: flex; */
}

.btn-code {
  position: absolute;
  top: 7px;
  right: -10px;
}

/deep/ .el-form-item__error {
  padding-left: 15px;
}

/deep/ .ant-steps-dot .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  height: 10px;
}

/deep/ .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  display: none;
}

/deep/ .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-do {
  height: 0%;
}

.imgdiv {
  height: 200px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../../assets/img/forget_pass.png) center top / 246px no-repeat;

}

.successImg {
  height: 200px;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../../assets/img/succ_congra.png) center top / 246px no-repeat;

}

.imgs {
  width: 300px;
  height: 200px;
}

.titleView {
  text-align: center;
  margin-top: 20px;
  color: #000000;
  font-size: 20px;
  font-family: PingFangSC-Medium;
}

.tipView {
  color: #666;
  font-size: 14px;
  padding: 20px 0;
}
</style>
